//@ts-nocheck
import React, { useState, useEffect, useContext } from "react";
import Client from "shopify-buy";
import { getLocalState } from "../helper/local-storage";
import { setSessionState, getSessionState, clearSessionState } from "../helper/session-storage";
import { CART_PRODUCTS, LOGGEDINUSER } from "./local-cart-provider";
//import awsconfig from '../aws-exports';
//import Amplify from 'aws-amplify';
import { LocalCartContext } from './local-cart-provider';
import { navigate } from 'gatsby';
//Amplify.configure(awsconfig);

export const CartContext = React.createContext();

const client = Client.buildClient({
  storefrontAccessToken: process.env.GATSBY_SHOPIFY_ACCESS_TOKEN, // Load from env
  domain: `${process.env.GATSBY_SHOP_NAME}.myshopify.com`, // Load from env
});

const CartProvider = ({ children }) => {
  let initialStoreState = {
    client,
    updating: false,
    checkout: { lineItems: [] },
    products: [],
    shop: {},
    customerToken: null,
    unavailable: []
  };
	const { updateAvailability } = useContext(
		LocalCartContext
	);
  const [store, updateStore] = useState(initialStoreState);
  const [initialize, setIntialize] = useState(false);

  /*useEffect(() => {
    const initializeCheckout = async () => {
      const setCheckoutInState = (checkout) => {
        updateStore((prevState) => {
          return { ...prevState, checkout };
        });
      };
      const createNewCheckout = () => store.client.checkout.create();
      const newCheckout = await createNewCheckout();
      setCheckoutInState(newCheckout);
    };

    initializeCheckout();
  }, [initialize]);*/

  return (
    <CartContext.Provider
      value={{
        store,
        addToCartAndCheckout: async () => {
          const { checkout, client , customerToken} = store;
          const checkoutId = checkout.id;
          updateStore((prevState) => {
            return { ...prevState, updating: true };
          });
          const products = getLocalState(CART_PRODUCTS);
          const lineItemsToUpdate = [];

          products.forEach((product) => {
            lineItemsToUpdate.push({
              variantId: product.variantId,
              quantity: product.quantity,
            });
          });
          let windowReference = window.open("", "_self");
          if(window.self !== window.top) {
            windowReference = window.open("", "_blank");
          }
          //const delivery = {customAttributes: [{key: "DeliveryDate", value: deliveryDate}]};
		  const referrer = getSessionState('referrer');
		  const discountCode = '5W04HPT7R9BT'
          const loggedinUser = getSessionState(LOGGEDINUSER);
          let customAttributes
          if (referrer) {
             customAttributes = {customAttributes: [{key: "loggedinUser", value: loggedinUser}, {key: "referrer", value: referrer}]};          
          }
          else {
             customAttributes = {customAttributes: [{key: "loggedinUser", value: loggedinUser}]};          
          }
		  
          return client.checkout
            .addLineItems(checkoutId, lineItemsToUpdate)
            .then(()=> client.checkout.updateAttributes(checkoutId, customAttributes))
            .then((checkout)=> {
            	if(referrer) {
            	  clearSessionState();
            	  return client.checkout.addDiscount(checkoutId, discountCode)  
            	}
            	else {
            	  return checkout;
            	}
            	})           	
            .then(async(checkout) => {
				/*if (customerToken === null) {
					 const user = await Amplify.Auth.currentUserInfo(); 
					 var tokenJson = null;
					 console.log("user: ", user);
				 
					 if (user) {
						 const email =  user.attributes.email;  
						 const pass =  user.attributes.sub.substring(0, 13);
						  const query = `mutation {
								  customerAccessTokenCreate(
									  input: {
										email: "${email}",
										password: "${pass}"
									  }
									)
									{
										customerAccessToken {
										  accessToken
										  expiresAt
										}
										customerUserErrors {
										  code
										  field
										  message
										}
									  }            
								}
							`;

							var requestOptions = {
								method: 'POST',
								headers: { 'Content-Type': 'application/graphql',
											'Accept': 'application/json',
											'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
								body: query
							};
	
							const response = await fetch(`https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2020-07/graphql`, requestOptions );
							tokenJson = await response.json();
							//console.log(tokenJson); 
						}
					
					  const setCustomerTokentInState = (customerToken) => {
						updateStore((prevState) => {
						  return { ...prevState, customerToken };
						});
					  };
					  if (tokenJson) {
						 const accessToken = tokenJson.data.customerAccessTokenCreate.customerAccessToken.accessToken;
						 setCustomerTokentInState(accessToken);
						 const CustomerAssociateQuery = `mutation {
							  checkoutCustomerAssociateV2(
									checkoutId: "${checkoutId}",
									customerAccessToken: "${accessToken}"
								)
								{
									checkout {
									  id
									  webUrl
									}
									checkoutUserErrors {
									  code
									  field
									  message
									}
									customer {
									  id
									}
								  }          
							}
						`;   
						var requestOptions = {
							method: 'POST',
							headers: { 'Content-Type': 'application/graphql',
										'Accept': 'application/json',
										'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
							body: CustomerAssociateQuery
						};

						const response = await fetch(`https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2020-07/graphql`, requestOptions );
						//console.log("response", await response.json()); 				 	
					  }     
				  } ; */         
			
				  //windowReference.location = checkout.webUrl;
				  //window.location.href = checkout.webUrl.replace(`${process.env.GATSBY_SHOP_NAME}.myshopify.com`, process.env.SHOP_DOMAIN_NAME);
				  setSessionState("checkoutId", checkoutId)
				  windowReference.location = checkout.webUrl.replace(`${process.env.GATSBY_SHOP_NAME}.myshopify.com`, process.env.SHOP_DOMAIN_NAME);
				  //console.log(JSON.stringify(checkout));
				  setIntialize(Date.now());
				  updateStore((prevState) => {
					return {
					  ...prevState,
					  checkout,
					  updating: false,
					};
				  });
              });              
        }, 
		checkQuantity: () => {
          updateStore((prevState) => {
            return { ...prevState, updating: true };
          });
          let variantIds = []; 
          let variantNo = 0;
          let variantIdText = '';
          const products = getLocalState(CART_PRODUCTS);
          products.forEach((product) => {
            /*lineItemsToUpdate.push({
              variantId: product.variantId,
              quantity: product.quantity,
            });*/
            //variantIds.push(`(id:${product.variantId})`)
            variantIds.push(`"${product.variantId}"`)
            variantNo = variantNo + 1;  
          });
          //variantIdText = variantIds.join(' OR ');  
          variantIdText = variantIds.join(',');         
          /*let query = `{
			  productVariants(query: "${variantIdText}", first: ${variantNo}) {
				edges {
				  node {
					id
					inventoryQuantity
				  }
				}
			  }
			}`;*/
			let query = `{
			  nodes(ids: [${variantIdText}]) {
				... on ProductVariant {
				  id
				  quantityAvailable
				}
			  }
			}`;
			var requestOptions = {
				method: 'POST',
				headers: { 'Content-Type': 'application/graphql',
							'Accept': 'application/json',
							'X-Shopify-Storefront-Access-Token': process.env.GATSBY_SHOPIFY_ACCESS_TOKEN},
				body: query
			};

			/*const response = await fetch(`https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2020-07/graphql`, requestOptions );
			const responseJson = await response.json();
			let unavailable = [];
			responseJson.data.nodes.map(variant => {
				products.map(product => {
				  if(product.variantId === variant.id && product.quantity > variant.quantityAvailable) {
				  	unavailable.push({varianId: product.variantId, availableQuantity: variant.quantityAvailable})
				  }
				})
			});
			console.log(`unavailable: ${JSON.stringify(unavailable)}`);			
			updateStore((prevState) => {
			  return {
				...prevState,
				unavailable: unavailable,
				updating: false,
			  };
			});	*/	
			return 	fetch(`https://${process.env.GATSBY_SHOP_NAME}.myshopify.com/api/2020-07/graphql`, requestOptions )
			.then(response => response.json())
			.then(responseJson => {
				let unavailable = [];
				responseJson.data.nodes.map(variant => {
					products.map(product => {
					  if(product.variantId === variant.id && product.quantity > variant.quantityAvailable) {
						unavailable.push({varianId: product.variantId, availableQuantity: variant.quantityAvailable})
					  }
					})
				});
				//console.log(`unavailable: ${JSON.stringify(unavailable)}`);	
				//if(unavailable.length > 0) {}
				updateAvailability(unavailable)	
				if(unavailable.length == 0) {
				  navigate('/checkout/shipping')
				}					
				updateStore((prevState) => {
				  return {
					...prevState,
					unavailable: unavailable,
					updating: false,
				  };
				});
			})
		},              
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

export default CartProvider;
